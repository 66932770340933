import { jsx } from "react/jsx-runtime";
import { lazy, useMemo } from "react";
const LazyFormFieldset = lazy(
  () => import("./form-fieldset-CpigSHEW.js").then((module) => ({
    default: module.FormFieldset
  }))
);
const LazyFieldset = lazy(
  () => import("./fieldset-DvLYi90F.js").then((n) => n.f).then((module) => ({
    default: module.Fieldset
  }))
);
function FormFieldset({
  id: _id,
  name: _name,
  ...props
}) {
  return /* @__PURE__ */ jsx(LazyFormFieldset, { ...props });
}
const useFormFieldsetContent = () => {
  return useMemo(
    () => ({
      formFieldset: {
        component: FormFieldset
      }
    }),
    []
  );
};
export {
  LazyFieldset as Fieldset,
  LazyFormFieldset as FormFieldset,
  useFormFieldsetContent
};
